import React from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import PopularService from '../components/PopularService'
import VideoCounter from '../components/VideoCounter'
import Faq from '../components/Faq'
import Testimonial2 from '../components/Testimonial2'
import Testimonial1 from '../components/Testimonial1'
import Progress from '../components/Progress'
import Brand from '../components/Brand'
import Error404 from './Error404'
import Vision from './Vision'
import { motion } from "framer-motion";

const workingProcess = [
  { num: "01", heading: "Receive Project" },
  { num: "02", heading: "Planning Work" },
  { num: "03", heading: "Creative Design" },
  { num: "04", heading: "Start Building" },
];

const Services = () => {
  const firstItem = workingProcess[0];
  const secondItem = workingProcess[1];
  const thirdItem = workingProcess[2];
  const forthItem = workingProcess[3];

    return (
        <>
            <div className="page-content bg-white">
            <CommanBanner mainTitle="OUR SERVICE" bgImage={IMAGES.bnr9} />
            
            {/* <section className="dz-content-bx style-3">
                <VideoCounter />
            </section> */}
            {/* <section className="content-inner-2" style={{ backgroundImage: `url(${IMAGES.bg2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <Testimonial1 />
            </section> */}
            <section className="content-inner-2">
                <Testimonial2 />
            </section>
            {/* <section className="section-full content-inner overflow-hidden" style={{ backgroundImage: `url(${IMAGES.bg1})`, backgroundPosition: 'left top', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <Faq />
            </section> */}
            {/* <section className="section-full dz-content-bx style-2 text-white" >
                <Progress />
            </section> */}

            
<section class="about_section about_section1 layout_padding">
    <div class="container  ">
      <div class="row">
        <div class="col-lg-6 ">
          <div class="img-box"style={{ flex: 1, paddingRight: '20px' }}>
          <img src={IMAGES.services01} alt="" style={{ width: '500px', height: '300px', marginBottom: '120px' }} />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
              Residential Construction
              </h2>
            </div>
            <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            Cyberidge Construction delivers bespoke residential construction solutions, encompassing new builds, project management, renovations, and extensions. Whether constructing from the ground up or transforming an existing property, we ensure precision, quality, and seamless execution.
            </p>
            <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            With expertise in creating functional and elegant spaces, our services are tailored to your vision, enhancing both value and liveability. Trust Cyberidge for professional, efficient, and uncompromising results.
            </p>
            <a href="">
            
            </a>
          </div>
        </div>
      </div>
    </div>
  </section> 


  <section class="about_section about_section2 layout_padding-bottom">
    <div class="container  ">
      <div class="row">
        <div class="col-lg-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
              Residential Planning and Design
              </h2>
            </div>
            <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            Cyberidge Construction provides specialised Residential Planning and Design services, focusing on the critical early stages of a project. Our expertise spans architectural design, structural engineering, and energy efficiency assessments, ensuring each project is meticulously crafted and aligned with functional, regulatory, and aesthetic goals. By leveraging advanced tools and techniques, we optimise designs to achieve a clear vision, structural integrity, and sustainable outcomes, laying a solid foundation for successful execution.
            </p>
            <a href="">
            </a>
          </div>
        </div>
        <div class="col-lg-6 ">
          <div class="img-box">
          <img src={IMAGES.services02} alt="" style={{ width: '500px', height: '300px', marginBottom: '120px' }} />
          </div>
        </div>
      </div>
    </div>
  </section>


    <section class="about_section about_section1 layout_padding">
    <div class="container  ">
      <div class="row">
        <div class="col-lg-6 ">
          <div class="img-box">
          <img src={IMAGES.services03} alt="" style={{ width: '500px', height: '300px', marginBottom: '120px' }} />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
              Residential Development
              </h2>
            </div>
            <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            Cyberidge Construction offers end-to-end Residential Development services, managing the entire lifecycle of a project from initiation to final delivery. Beyond initial planning, we oversee implementation, navigating approval processes, securing tailored financing, and coordinating with builders to ensure smooth and efficient execution. Our approach focuses on delivering projects that meet the highest standards while addressing every challenge along the way.
            </p>
            <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            With advanced technologies such as big data analytics and intelligent planning tools, we optimise resources, accelerate timelines, and maintain rigorous quality control. Cyberidge Construction ensures that every residential development is seamlessly delivered, balancing functionality, sustainability, and architectural excellence.
            </p>
            <a href="">
            
            </a>
          </div>
        </div>
      </div>
    </div>
  </section> 

  <section className="content-inner-2">
          <div className="container">
            <div className="section-head style-1 text-center">
              <h6 className="sub-title text-primary">WORK PROCESS</h6>
              <h2 className="title">How Our Work Process</h2>
            </div>
            <div className="row">
              {/* First Item */}
              <motion.div
                className="col-lg-4 col-sm-4"
                key={firstItem.num}
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ duration: 0.8 }}
              >
                <div className="work-process shadow text-center m-b30">
                  <div className="number">{firstItem.num}</div>
                  <h4 className="title m-b15">
                  Residential Construction Service Process
                  </h4>
                  <h6 style={{ textAlign: 'left' }}>1. Initial Consultation</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  We begin by understanding your needs, preferences, and goals for the construction project. Our team will assess the site and discuss design possibilities, timelines, and budget estimates.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>2. Design Development and Approval</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  Our experts create detailed plans and 3D visualizations to bring your vision to life. We ensure all designs meet regulatory requirements and align with your expectations. Once finalised, we seek your approval to proceed.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>3. Construction Planning</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  We develop a comprehensive construction plan, including timelines, material sourcing, and labor allocation. Our team ensures all preparations are in place for a smooth building process.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>4. Construction Execution</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  Our skilled professionals bring the project to life, maintaining the highest standards of quality and craftsmanship. We keep you updated with regular progress reports and address any concerns promptly.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>5. Final Handover</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  Upon completion, we conduct a thorough walkthrough with you to ensure satisfaction. Any necessary adjustments are made, and we provide maintenance guidelines to ensure long-term durability.
                  </p>
                </div>
              </motion.div>

              {/* Second Item */}
              <motion.div
                className="col-lg-4 col-sm-4"
                key={firstItem.num}
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ duration: 0.8 }}
              >
                <div className="work-process shadow text-center m-b30">
                  <div className="number">{firstItem.num}</div>
                  <h4 className="title m-b15">
                  Residential Planning and Design Service Process
                  </h4>
                  <h6 style={{ textAlign: 'left' }}>1. Initial Consultation</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  We meet with you to discuss your vision, functional needs, and aesthetic preferences. Our team gathers information about the site and project requirements to guide the design process.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>2. Concept Development</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  Our designers create preliminary sketches and conceptual designs to align with your goals. We focus on functionality, sustainability, and compliance with local building codes.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>3. Detailed Design Creation</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  We refine the concept into detailed plans, incorporating feedback from the consultation phase. Advanced 3D visualizations help you see the final design before construction begins.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>4. Regulatory Approvals</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  We handle all necessary paperwork and coordinate with authorities to ensure your project meets all zoning, safety, and regulatory standards.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>5. Final Design Handover</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  Once approved, we provide you with the finalised design package, ready for construction execution. We remain available for any clarifications or design adjustments as needed.
                  </p>
                </div>
              </motion.div>

              {/* Third Item */}
              <motion.div
                className="col-lg-4 col-sm-4"
                key={firstItem.num}
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ duration: 0.8 }}
              >
                <div className="work-process shadow text-center m-b30">
                  <div className="number">{firstItem.num}</div>
                  <h4 className="title m-b15">
                  Residential Development Service Process
                  </h4>
                  <h6 style={{ textAlign: 'left' }}>1. Consultation and Feasibility Assessment</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  Our team conducts an in-depth consultation to understand your development goals. We perform site analysis, market research, and feasibility studies to ensure the project is viable and profitable.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>2. Development Planning</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  We create a comprehensive development plan, outlining timelines, budgets, and resource requirements. This includes coordinating with architects, engineers, and financial consultants for a holistic approach.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>3. Pre-Construction Preparation</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  We handle permits, land preparation, and procurement of sustainable materials. Detailed planning ensures that all elements are ready for a seamless transition to the construction phase.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>4. Construction and Project Management</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  We oversee every stage of construction, ensuring strict adherence to timelines, quality standards, and budget constraints. Regular updates keep you informed about the project's progress.
                  </p>
                  <h6 style={{ textAlign: 'left' }}>5. Project Completion and Handover</h6>
                  <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word', letterSpacing: '-0.05em' }}>
                  Upon completion, we conduct a final inspection to ensure all aspects of the development meet your expectations. The project is handed over with comprehensive documentation and ongoing support options.
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </section>
              
              <br></br>
              <br></br>
              <br></br>


                {/* <section className="section-full content-inner about-bx2" style={{ backgroundImage: `url(${IMAGES.background2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <div className="container">
                        <Error404/>
                    </div>
                </section> */}

                {/* <section className="section-full content-inner about-bx2" style={{ backgroundImage: `url(${IMAGES.background2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <div className="container">
                        <Vision/>
                    </div>
                </section> */}

            {/* <section className="content-inner-1">
                <div className="container">
                    <div className="section-head style-1 text-center">
                        <h6 className="sub-title text-primary">Brands</h6>
                        <h2 className="title">Our Top Partners</h2>
                    </div>
                    <Brand />
                </div>
            </section> */}
            </div>
        </>
    )
}

export default Services