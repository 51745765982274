import React from "react";
import CommanBanner from "../elements/CommanBanner";
import { IMAGES } from "../constants/theme";
import Working from "../components/Working";
import Projects from "../components/Projects";
import Testimonial1 from "../components/Testimonial1";
import Testimonial2 from "../components/Testimonial2";
import Faq from "../components/Faq";
import { motion } from "framer-motion";
import ComingSoon from "./ComingSoon";
import Error404 from "./Error404";
import Brand from "../components/Brand";
import TeamMembers from "./Team";

const AboutUs = () => {
  return (
    <>
      <div className="page-content bg-white">
        <CommanBanner
          mainTitle="ABOUT US"
          bgImage={IMAGES.bnr9}
        />
        <section
          className="section-full content-inner about-bx2"
          style={{
            backgroundImage: `url(${IMAGES.background2})`,
            backgroundPosition: "right bottom",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <div className="container">
            <ComingSoon />
          </div> */}
        </section>

        <div className="section-head style-1 text-center">
          <h6 className="sub-title text-primary">CYBERIDGE CONSTRUCTION</h6>
        </div>

        <section
          className="about_section about_section2 layout_padding-bottom"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="heading_container"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <h2
              className="dz-title ml2"
              style={{
                fontFamily: "Arial Black, Gadget, sans-serif",
                fontSize: "46px",
                fontWeight: "bold",
                lineHeight: "1.2",
              }}
            >
              <span>Leading the Way in </span>
              <br />
              <span>Sustainable Construction</span>
            </h2>
          </div>
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              className="row"
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                className="col-lg-6"
                style={{ flex: 1, paddingRight: "20px" }}
              >
                <div className="detail-box">
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                For property developers and investors seeking high-quality and sustainable construction services, Cyberidge Construction is a reliable company offering the most efficient and eco-friendly building solutions. Our commitment to sustainable practices, quality craftsmanship, and innovative construction technologies ensures that every project meets the highest standards.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ flex: 1, paddingLeft: "20px" }}
              >
                <div className="img-box" style={{ textAlign: "center" }}>
                  <img
                    src={IMAGES.about001}
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "600px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <br></br>
        <br></br>
        <br></br>

        <section
          className="about_section about_section2 layout_padding-bottom"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="heading_container"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <h2
              className="dz-title ml2"
              style={{
                fontFamily: "Arial Black, Gadget, sans-serif",
                fontSize: "46px",
                fontWeight: "bold",
                lineHeight: "1.2",
              }}
            >
              <span>Our Story </span>
              <br />
            </h2>
          </div>
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              className="row"
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                className="col-lg-12"
                style={{paddingRight: "20px" }}
              >
                <div className="detail-box">
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                As a proud member of the DDDI Group, Cyberidge Construction has a strong foundation in delivering high-quality construction services, backed by years of industry expertise. Our focus is on providing property developers and investors with reliable, efficient, and comprehensive building solutions.
                  </p>
                  <br></br>
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                With a team of experienced professionals and a track record of successful projects, we specialise in managing complex construction projects from start to finish, ensuring timely delivery and top-tier craftsmanship. Our commitment to excellence drives every aspect of our work, from initial planning and design to the final stages of construction.
                </p>
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                At Cyberidge Construction, we offer more than just building services; we provide a seamless, integrated experience by collaborating with other DDDI Group members—Cyberate Investments, Cyberate Technologies, and Cyberate Finance. This collaboration allows us to deliver full-service solutions, covering everything from investment consulting and financing to technology integration and construction execution. Our clients benefit from a streamlined process that ensures efficiency and success in every project.
                </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ flex: 1, paddingLeft: "20px" }}
              >
              </div>
            </div>
          </div>
        </section>

        <br></br>
        <br></br>
        <br></br>

        <section
          className="about_section about_section2 layout_padding-bottom"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="heading_container"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <h2
              className="dz-title ml2"
              style={{
                fontFamily: "Arial Black, Gadget, sans-serif",
                fontSize: "46px",
                fontWeight: "bold",
                lineHeight: "1.2",
              }}
            >
              <span>Our Mission</span>
              <br />
            </h2>
          </div>
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              className="row"
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                className="col-lg-12"
                style={{paddingRight: "20px" }}
              >
                <div className="detail-box">
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                To deliver high-quality and sustainable construction services, ensuring efficiency and environmental responsibility in every project we undertake.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ flex: 1, paddingLeft: "20px" }}
              >
              </div>
            </div>
          </div>
        </section>

        <br></br>
        <br></br>
        <br></br>

        <section
          className="about_section about_section2 layout_padding-bottom"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="heading_container"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <h2
              className="dz-title ml2"
              style={{
                fontFamily: "Arial Black, Gadget, sans-serif",
                fontSize: "46px",
                fontWeight: "bold",
                lineHeight: "1.2",
              }}
            >
              <span>Future Vision</span>
              <br />
            </h2>
          </div>
          <h3 style={{textAlign:'center'}}>
                         "Our Future Vision is a <span style={{color:'#a3cc02'}}>Green Future</span>"
          </h3>
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              className="row"
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                className="col-lg-12"
                style={{paddingRight: "20px" }}
              >
                <div className="detail-box">
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                To be the most reliable and environmentally responsible construction company, admired for our commitment to sustainability, quality craftsmanship, and innovative project management.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ flex: 1, paddingLeft: "20px" }}
              >
              </div>
            </div>
          </div>
        </section>

        <br></br>
        <br></br>
        <br></br>

        <section
          className="about_section about_section2 layout_padding-bottom"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="heading_container"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <h2
              className="dz-title ml2"
              style={{
                fontFamily: "Arial Black, Gadget, sans-serif",
                fontSize: "46px",
                fontWeight: "bold",
                lineHeight: "1.2",
              }}
            >
              <span>Our Values </span>
              <br />
            </h2>
          </div>
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              className="row"
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                className="col-lg-12"
                style={{paddingRight: "20px" }}
              >
                <div className="detail-box">
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                <strong style={{fontWeight:'800'}}>Sustainability:</strong> Committed to promoting green building and sustainable practices and reducing environmental impact through the use of environmentally friendly materials and technologies.
                  </p>
                  <br></br>
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                <strong style={{fontWeight:'800'}}>Quality:</strong> Insisting on high quality workmanship and excellence in project management to ensure that every project meets or exceeds our clients' expectations.
                </p>
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                <strong style={{fontWeight:'800'}}>Innovation:</strong> Continuously innovate and adopt the latest building technologies and methods to enhance building efficiency and environmental performance.
                </p>
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                <strong style={{fontWeight:'800'}}>Reliability:</strong> Being a trusted partner to our clients, ensuring projects are completed on time and on budget, and providing enduring support and service.
                </p> 
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ flex: 1, paddingLeft: "20px" }}
              >
              </div>
            </div>
          </div>
        </section>

        <div style={{margin:'0px', padding:'0px', border: '0px'}}>
        <section
          className="content-inner-2"
          style={{
            paddingTop:'0px',
            backgroundImage: `url(${IMAGES.bg2})`,
            backgroundPosition: "right bottom",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <Testimonial1 /> */}
        </section>
        <section className="content-inner-2">{/* <Testimonial2 /> */}</section>
        <section
          className="section-full content-inner overflow-hidden"
          style={{
            paddingTop:'0px',
            backgroundImage: `url(${IMAGES.bg1})`,
            backgroundPosition: "left top",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            className="heading_container"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <h2
              className="dz-title ml2"
              style={{
                fontFamily: "Arial Black, Gadget, sans-serif",
                fontSize: "46px",
                fontWeight: "bold",
                lineHeight: "1.2",
              }}
            >
              <span>Our Team</span>
              <br />
            </h2>
          </div>
          <TeamMembers />
          <br></br>
          <Faq />
        </section>
        </div>
{/* 
        <section class="team-bg">
          <div class="container">
            <div class="row">
              <div className="section-head style-1 text-center">
                <h6 className="sub-title text-primary">Team</h6>
                <h2 className="title">Our Team</h2>
              </div>
            </div>
          </div>
        </section>

        <section
          className="team-bg"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center"></div>
            </div>
          </div>
        </section>
        <section className="team-section-1">
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="team-slider"
                  style={{ display: "flex", overflowX: "auto", gap: "20px" }}
                >
                  <div
                    className="team-item-01"
                    style={{
                      flex: "0 0 auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="tm-thumb"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={IMAGES.about01}
                        alt=""
                        style={{
                          width: "325px",
                          height: "400px",
                          borderRadius: "15px", // Add this line
                        }}
                      />
                    </div>
                    <div className="tm-content" style={{ textAlign: "center" }}>
                      <h5>William Jiang</h5>
                      <p>Director</p>
                    </div>
                  </div>
                  <div
                    className="team-item-01"
                    style={{
                      flex: "0 0 auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="tm-thumb"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={IMAGES.about01}
                        alt=""
                        style={{
                          width: "325px",
                          height: "400px",
                          borderRadius: "15px", // Add this line
                        }}
                      />
                    </div>
                    <div className="tm-content" style={{ textAlign: "center" }}>
                      <h5>Peter Xiao</h5>
                      <p>Civil Engineer</p>
                    </div>
                  </div>
                  <div
                    className="team-item-01"
                    style={{
                      flex: "0 0 auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="tm-thumb"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={IMAGES.about01}
                        alt=""
                        style={{
                          width: "325px",
                          height: "400px",
                          borderRadius: "15px", // Add this line
                        }}
                      />
                    </div>
                    <div className="tm-content" style={{ textAlign: "center" }}>
                      <h5>Viv Shi</h5>
                      <p>Strategy Manager</p>
                    </div>
                  </div>
                  <div
                    className="team-item-01"
                    style={{
                      flex: "0 0 auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="tm-thumb"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={IMAGES.about01}
                        alt=""
                        style={{
                          width: "325px",
                          height: "400px",
                          borderRadius: "15px", // Add this line
                        }}
                      />
                    </div>
                    <div className="tm-content" style={{ textAlign: "center" }}>
                      <h5>Ava Li</h5>
                      <p>Civil Engineer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-inner-1">
          <div className="container">
            <div className="section-head style-1 text-center">
              <h6 className="sub-title text-primary">Brands</h6>
              <h2 className="title">Our Top Partners</h2>
            </div>
            <Brand />
          </div>
        </section> */}
      </div>
    </>
  );
};
export default AboutUs;
