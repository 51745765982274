import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const card = [
    { id: 1, delay: 0.2, icon: 'flaticon-blueprint-1' },
    { id: 2, delay: 0.4, icon: 'flaticon-crane' },
    { id: 3, delay: 0.6, icon: 'flaticon-interior-design-1' },
];

const PopularService = () => {
    const [addActive, setAddActive] = useState(1);
    const [activeIndex, setActiveIndex] = useState(null);
    const firstItem = card[0];
    const secondItem = card[1];
    const thirdItem = card[2];

const toggleItem = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

    return (
        <>

            <div className="container-fluid">
                <div className="section-head style-1 text-center">
                <h2 className="title">OUR FEATURE SERVICES</h2>
                </div>
                <div className="row">
                    {[firstItem].map((item, index) => (
                        <motion.div key={firstItem.id} className="col-lg-4 col-sm-6 aos-item"
                            
                        >
                            <div className={`icon-bx-wraper style-3 m-b30 ${addActive === firstItem.id ? 'active' : ''}`}
                                onMouseEnter={() => setAddActive(firstItem.id)}
                            >
                                <div className="icon-xl m-b30">
                                    <Link className="icon-cell"><i className={firstItem.icon}></i></Link>
                                </div>
                                <div className="icon-content" >
                                    <h4 className="title m-b10"><Link to="/service-details">Residential Construction</Link></h4>
                                    <p className="m-b30">{firstItem.text}</p>
                                    <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>We provide tailored construction solutions for residential projects, including new builds, renovations, and extensions. Our team of skilled professionals ensures that every detail is executed with precision and craftsmanship. Whether you're building a family home or upgrading an existing property, we prioritise quality and a seamless construction experience.</p>
                                    <ul>
                                    <li onClick={() => toggleItem(0)}>
                                    <p style={{fontWeight: 'bold'}}>Custom New Builds ▼</p>
                                    {activeIndex === 0 && <p>From concept to completion, we craft homes that meet your unique vision and lifestyle requirements.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(1)}>
                                    <p style={{fontWeight: 'bold'}}>Renovations ▼</p>
                                    {activeIndex === 1 && <p>Transform your existing spaces into modern, functional, and beautiful living areas with our expert renovation services.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(2)}>
                                    <p style={{fontWeight: 'bold'}}>Extensions ▼</p>
                                    {activeIndex === 2 && <p>Add value and space to your property with thoughtfully designed and flawlessly executed extensions.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(3)}>
                                    <p style={{fontWeight: 'bold'}}>Sustainable Practices ▼</p>
                                    {activeIndex === 3 && <p>Incorporating eco-friendly materials and technologies to create homes that are both efficient and environmentally responsible.</p>}
                                    </li>
                                    </ul>

                                    <br></br>

                                    <Link to="/services" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                        <span>Read More</span>
                                        <i className="fas fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </motion.div>
                    ))}

                        {[secondItem].map((item, index) => (
                        <motion.div key={secondItem.id} className="col-lg-4 col-sm-6 aos-item"
                            
                        >
                            <div className={`icon-bx-wraper style-3 m-b30 ${addActive === secondItem.id ? 'active' : ''}`}
                                onMouseEnter={() => setAddActive(secondItem.id)}
                            >
                                <div className="icon-xl m-b30">
                                    <Link className="icon-cell"><i className={secondItem.icon}></i></Link>
                                </div>
                                <div className="icon-content">
                                    <h4 className="title m-b10"><Link to="/service-details">Residential Planning and Design</Link></h4>
                                    <p className="m-b30">{secondItem.text}</p>
                                    <p className="m-b30" style={{ fontSize: '14px' }}>We provide comprehensive planning and design services to ensure your residential project aligns with your cost, functional needs, aesthetic vision, and compliance requirements. Our design experts work closely with clients to bring their ideas to life, balancing innovation and practicality.</p>
                                    <ul style={{ fontSize: '14.2px' }}>
                                    <li onClick={() => toggleItem(4)}>
                                    <p style={{fontWeight: 'bold'}}>Conceptual Design ▼</p>
                                    {activeIndex === 4 && <p>Creating initial designs that capture your vision and set the foundation for your project.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(5)}>
                                    <p style={{fontWeight: 'bold'}}>Regulatory Compliance ▼</p>
                                    {activeIndex === 5 && <p>Ensuring all designs meet local building codes and planning regulations.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(6)}>
                                    <p style={{fontWeight: 'bold'}}>Sustainable Design ▼</p>
                                    {activeIndex === 6 && <p>Integrating energy-efficient and environmentally conscious solutions into every plan.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(7)}>
                                    <p style={{fontWeight: 'bold'}}>3D Visualization ▼</p>
                                    {activeIndex === 7 && <p>Offering advanced 3D modeling to help you visualise the final outcome and make informed decisions.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(8)}>
                                    <p style={{fontWeight: 'bold'}}>Collaborative Approach ▼</p>
                                    {activeIndex === 8 && <p>Working with architects, engineers, and other stakeholders to deliver a cohesive design plan.</p>}
                                    </li>
                                    </ul>

                                    <br></br>

                                    <Link to="/services" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                        <span>Read More</span>
                                        <i className="fas fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </motion.div>
                    ))}

                        {[thirdItem].map((item, index) => (
                        <motion.div key={thirdItem.id} className="col-lg-4 col-sm-6 aos-item"
                            
                        >
                            <div className={`icon-bx-wraper style-3 m-b30 ${addActive === thirdItem.id ? 'active' : ''}`}
                                onMouseEnter={() => setAddActive(thirdItem.id)}
                            >
                                <div className="icon-xl m-b30">
                                    <Link className="icon-cell"><i className={thirdItem.icon}></i></Link>
                                </div>
                                <div className="icon-content">
                                    <h4 className="title m-b10"><Link to="/service-details">Residential Development</Link></h4>
                                    <p className="m-b30">{thirdItem.text}</p>
                                    <p>Our end-to-end development services ensure the successful realisation of residential projects, from the initial planning stages to the final construction. We specialise in creating development solutions tailored to the needs of property developers and investors.</p>
                                    <ul style={{ fontSize: '13.2px' }}>
                                    <li onClick={() => toggleItem(9)}>
                                    <p style={{fontWeight: 'bold'}}>Site Analysis and Acquisition ▼</p>
                                    {activeIndex === 9 && <p>Assisting in site selection and acquisition, leveraging data-driven insights to identify prime opportunities.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(10)}>
                                    <p style={{fontWeight: 'bold'}}>Feasibility Studies ▼</p>
                                    {activeIndex === 10 && <p>Conducting detailed analyses to ensure the financial and technical viability of your project.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(11)}>
                                    <p style={{fontWeight: 'bold'}}>Project Management ▼</p>
                                    {activeIndex === 11 && <p>Managing all aspects of the development process, including timelines, budgets, and coordination with stakeholders.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(12)}>
                                    <p style={{fontWeight: 'bold'}}>Sustainability Focus ▼</p>
                                    {activeIndex === 12 && <p>Delivering developments that prioritise costs, energy efficiency, resource conservation, and environmental responsibility.</p>}
                                    </li>
                                    <br></br>
                                    <li onClick={() => toggleItem(13)}>
                                    <p style={{fontWeight: 'bold'}}>Turnkey Solutions ▼</p>
                                    {activeIndex === 13 && <p>Providing a seamless experience from concept to completion, ensuring all aspects of the project are managed with excellence.</p>}
                                    </li>
                                    </ul>

                                    <br></br>

                                    <Link to="/services" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                        <span>Read More</span>
                                        <i className="fas fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default PopularService;
