import React from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import ReCAPTCHA from 'react-google-recaptcha'


const cards = [
  { id: '01', icon: "flaticon-telephone", title: "CALL NOW", detail: " " ,detail2: "+61 (08) 82579123"},
  { id: '02', icon: "flaticon-email", title: "EMAIL", detail: " " ,detail2: "info@cyberidge.com.au"},
  { id: '03', icon: "flaticon-placeholder", title: "LOCATION", detail: "3/22-28 Melbourne St, North Adelaide,SA, 5006",  },
]

const ContectUs = () => {
  function onChange(value) {
    console.log("Captcha value:", value);
  }
  return (
    <>
      <div className="page-content bg-white">
        <CommanBanner mainTitle="CONTACT US" bgImage={IMAGES.bnr9} />
        <section className="content-inner">
          <div className="container">
            <div className="row">
              {
                cards.map((i) => (
                  <div key={i.id} className="col-lg-4 col-md-12 m-b30 aos-item" style={{justifyContent:'center', alignItems:'center', height: '150px'}}>
                    <div className="icon-bx-wraper style-8 bg-white" data-name={i.id}>
                      <div className="icon-md m-r20">
                        <span className="icon-cell text-primary"><i className={i.icon}></i></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="tilte m-b10">{i.title}</h4>
                        <p className="m-b0">{i.detail}<br />{i.detail2}</p>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
        <section className="content-inner-1 pt-0">
          <div className="map-iframe">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.0415146450982!2d138.6073984750736!3d-34.90540767349253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0c93d21bc11eb%3A0xacefe376c63ebb42!2sUnit%203%2F22%20Melbourne%20St%2C%20North%20Adelaide%20SA%205006!5e0!3m2!1sen!2sau!4v1700006371526!5m2!1sen!2sau" className="align-self-stretch radius-sm" style={{ border: 0, width: '100%', minHeight: '100%' }} allowFullScreen></iframe>
          </div>
          <br/> <br/> <br/> <br/> <br/> <br/> <br/> 
          <div className="container">
            <div className="contact-area aos-item">
              <div className="section-head style-1 text-center">
                <h6 className="sub-title text-primary">CONTACT US</h6>
                <h2 className="title">Get In Touch With Us</h2>
              </div>
              <form className="dz-form dzForm contact-bx" >
                <div className="dzFormMsg"></div>
                <div className="row sp10">
                  <div className="col-sm-6 m-b20">
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzFirstName" placeholder="First Name" />
                    </div>
                  </div>
                  <div className="col-sm-6 m-b20">
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzLastName" placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="col-sm-6 m-b20" style={{ width: '100%' }}>
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzEmail" placeholder="Email" />
                    </div>
                  </div>
                  <div className="col-sm-6 m-b20">
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzPhoneNumber" placeholder="Phone No." />
                    </div>
                  </div>
                  <div className="col-sm-12 m-b20">
                    <div className="input-group">
                      <input type="text" className="form-control" required name="dzOther" placeholder="Subject" />
                    </div>
                  </div>
                  <div className="col-sm-12 m-b20">
                    <div className="input-group">
                      <textarea name="dzMessage" rows="5" className="form-control" placeholder="Message"></textarea>
                    </div>
                  </div>
                
                  <div className="col-sm-12 text-center">
                    <button name="submit" className="btn btn-primary btn-rounded">SUBMIT <i className="m-l10 fas fa-caret-right"></i></button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default ContectUs