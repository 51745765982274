import React from "react";
import { IMAGES } from "../constants/theme";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

const teamCard = [
    { teamImg: IMAGES.team1, name: "Engineer", skill: "Engineer" },
    { teamImg: IMAGES.team2, name: "Lindsey Botosh", skill: "Manager Effiect" },
    { teamImg: IMAGES.team3, name: "Kaylynn Donin", skill: "CEO Factory" },
    { teamImg: IMAGES.team4, name: "Ava Li", skill: "Civil Engineer", backGround: "Ava Li is a Civil Engineer with a Bachelor's degree from the University of South Australia. With both academic and professional experience gained locally, Ava has a deep understanding of the region's construction standards and practices. Her expertise in structural design and sustainable solutions ensures the delivery of high-quality projects tailored to local needs." },
];

function Team() {
    return (
        <div className="page-content bg-white" style = {{margin: '60px'}}>
            <Swiper
                spaceBetween={20}
                loop={true}
                slidesPerView={4}
                autoplay={{
                    delay: 1000,
                    disableOnInteraction: false
                }}
                observer={true}
                observeParents={true}
            >
                {teamCard.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="team-card" style={{ margin: '0 40px', width: 'auto', height: 'auto'}}>
                            <div className="card dz-team style-1">
                                <div className="card-media">
                                    <img src={item.teamImg} alt={item.name} />
                                </div>
                                <div className="card-body">
                                    <h5 className="dz-name m-b5"><Link to="#">{item.name}</Link></h5>
                                    <span className="dz-position">{item.skill}</span>
                                    <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{item.backGround}</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default Team;
